import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Constants } from 'app/core/data/constants';
import { LabelValueModel } from 'app/core/models/filters.model';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private _breakpointState$: Observable<BreakpointState>;


  constructor(private _breakpointObserver: BreakpointObserver) {
    this._breakpointState$ = this._breakpointObserver.observe([
      Constants.mobileAndTabletBreakpoint,
      Constants.xSmallToSmallBreakpoint,
      Constants.smallToMediumBreakpoint,
      Constants.mediumToLargeBreakPoint,
      Constants.tabletLaptopScreens, /** true if width > 700px */
      Constants.largeBreakPoint,
      Constants.xLargeBreakPoint,
    ])
  }

  public getBreakpointState(): Observable<BreakpointState> {
    return this._breakpointState$;
  }

  /* map query params */
  mapQueryParams({ queryParams }: { queryParams: Map<string, any> }): string {
    const keys = queryParams.keys();
    const mappedParams = Array.from(keys).map((key) => {
      return  `${encodeURIComponent(key)}=${queryParams.get(key)}`
    })
    return mappedParams.join('&');
  }

  /** join list items */
  joinListItems({list}:{list:LabelValueModel[]}):string | null{
    return list?.length > 0 ? list.map((item)=>item.value).join(',') : null;
  }

  /** date formater */
  dateFormater = ({ dateToFormat }) => format(
    new Date(dateToFormat)
    ,'yyyy-MM-dd'
  );

  /* method to encode query params */
  encode({ valueToEncode }: { valueToEncode: any }): string {
    return btoa(valueToEncode);
  }
  
  /* method to decode query params */
  decode({ valueToDecode }: { valueToDecode: string }): any {
      return atob(valueToDecode);
  }
  
  readonly moreThanthirteenDaysAgo = () => { return null};

  readonly thirteenDaysAgo = () => { return format(new Date(new Date().setDate(new Date().getDate() - 30)), 'yyyy-MM-dd')};
  
  readonly sevenDaysAgo = () => { return format(new Date(new Date().setDate(new Date().getDate() - 7)), 'yyyy-MM-dd')};

  readonly threeDaysAgo = () => { return format(new Date(new Date().setDate(new Date().getDate() - 3)), 'yyyy-MM-dd')};
  
  readonly toDay = () =>{ return format(new Date(), 'yyyy-MM-dd'); }
 
}
